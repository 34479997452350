import { http, showCatch } from ".";

export async function generateQuestions(params: any) {
  let response;
  try {
    response = await http().post("/api/generative/process/questions", params);
    return response.data;
  } catch (e) {
    showCatch(e, "generateQuestions error. Response ", "generateQuestions error. ");
  }
}

export async function rephraseText(params: any) {
  let response;
  try {
    response = await http().post("/api/generative/rephrase", params);
    return response.data;
  } catch (e) {
    showCatch(e, "rephraseText error. Response ", "rephraseText error. ");
  }
}

export async function generateAnalysis(params: any) {
  let response;
  try {
    response = await http().post("/api/generative/candidate/analyze", params);
    return response.data;
  } catch (e) {
    showCatch(e, "generateAnalysis error. Response ", "generateAnalysis error. ");
  }
}

export async function generateJobDescription(params: any) {
  let response;
  try {
    response = await http().post("/api/generative/job/description", params);
    return response.data;
  } catch (e) {
    showCatch(e, "generateJobDescription error. Response ", "generateJobDescription error. ");
  }
}
